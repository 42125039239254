import React, { useEffect, useState } from "react";

import Slider from "react-slick";

import bg1 from "../../../assets/utils/images/originals/abstract2.jpg";
import { RiEyeCloseLine} from 'react-icons/ri';
import { ImEye } from 'react-icons/im'

import { Col, Row, Button, FormGroup, Label, Input, InputGroupAddon, InputGroup, InputGroupText, CustomInput } from "reactstrap";
import { Link } from "react-router-dom";
import { ErrorToast } from "../../../Layout/AppMain/Components/Toast";

import api from "../../../services/api";
import { formatPassword } from '../../../services/utils';

const Login = ({ history, setLoading, isAuthenticated }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    initialSlide: 0,
    autoplay: true,
    adaptiveHeight: true,
  };

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember_me: false
  });

  const [viewPassword, setViewPassword] = useState(false);
  const [invalidInput, setInvalidInput] = useState({
    email: '',
    password: '',
  });

  const toggleViewPassword = () => {
    setViewPassword(!viewPassword);
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  }

  const handleChange = (value, prop) => {
    if (prop === 'password') {
      value = formatPassword(value);
    }

    setFormData({
      ...formData,
      [prop]: value
    })
  }

  const handleValidate = () => {
    if (!formData.email || !formData.password) {
      ErrorToast({ placeholder: "Preencha e-mail e senha para continuar!" });
      return false;
    }

    return true;
  }

  const handleFormData = () => {
    return {
      ...formData,
    }
  }

  const handleLogin = async () => {
    if (!handleValidate()) {
      return;
    }

    setLoading(true);
    api.get('../sanctum/csrf-cookie').then(r => {
      api.post('login', handleFormData()).then(response => {
        localStorage.setItem('@userInfo', JSON.stringify(response.data));
        setLoading(false);
        window.location.assign('/');
      }).catch(e => {
        console.log(e);
        setLoading(false);
        let msg = e.errors ? e.errors.join("<br/>") : "Houve um problema com o login, verifique suas credenciais.";
        ErrorToast({ placeholder: msg });
      });
    }).catch(e => {
      console.log(e);
      setLoading(false);
      let msg = e.errors ? e.errors.join("<br/>") : "Ocorreu um erro interno ao tentar realizar o login, tente novamente mais tarde!";
      ErrorToast({ placeholder: msg });
    })
  }

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated])

  return (
    <>
      <div className="h-100">
        <Row className="h-100 no-gutters">

          <Col lg="4" className="d-none d-lg-block">
            <div className="slider-light">
              <Slider {...settings}>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div className="slide-img-bg"
                    style={{
                      backgroundImage: "url(" + bg1 + ")",
                    }}/>
                  <div className="slider-content">
                    <div className="app-logo" />
                    <br/>
                    <p>
                      conecte-se, faça mais.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </Col>

          <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
              <h4>
                <div>Boas vindas,</div>
                <span>
                  preencha os dados abaixo para entrar no sistema!
                </span>
              </h4>

              <div>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label>E-mail</Label>
                      <Input type="email" value={formData.email} onChange={({ target: { value } }) => handleChange(value, 'email')} placeholder="Digite seu e-mail aqui..." autoComplete="username" onKeyUp={handleKeyUp} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Senha</Label>
                      <InputGroup>
                        <Input type={viewPassword ? 'text' : 'password'} value={formData.password} onChange={({ target: { value } }) => handleChange(value, 'password')} placeholder="Digite sua senha aqui..." autoComplete="current-password" onKeyUp={handleKeyUp}/>
                        <InputGroupAddon addonType="append" onClick={toggleViewPassword}>
                            <InputGroupText>
                              <RiEyeCloseLine hidden={viewPassword}/>
                              <ImEye hidden={!viewPassword} />
                            </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <CustomInput type="checkbox" id="remember_me" checked={formData.remember_me} onChange={({ target: { checked } }) => handleChange(checked, 'remember_me')} label="Me mantenha logado" />
                </FormGroup>
                <Row className="divider" />
                <h6 className="mt-3">
                  Ainda não possui uma conta?{" "}
                  <Link to="/register" className="text-primary">
                    Teste grátis
                  </Link>
                </h6>
                <div className="d-flex align-items-center">
                  <div className="ml-auto">
                    <Link id="forgot_password" className="btn-lg btn btn-link" to="/forgot-password">
                      Recuperar Senha
                    </Link>{" "}
                    <Button type="button" color="primary" size="lg" onClick={handleLogin}>
                      Login
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Login;
