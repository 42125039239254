import React, { useState } from "react";

import {
  Popover,
  Nav,
  NavLink,
  Col,
  Row,
  NavItem
} from "reactstrap";
import { FaAngleDown } from "react-icons/fa";

const NavItemMega = ({data}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleToggle = () => {
    setPopoverOpen(!popoverOpen);
  }

  return (
    <>
      <NavItem>
        <NavLink href="#" onClick={handleToggle} id={`PopoverMegaMenu${data.label}`}>
          {data.label}
          <FaAngleDown />
        </NavLink>
      </NavItem>
      {data.content !== undefined && data.content.length > 0 &&
        <Popover className="rm-max-width" placement="bottom-start" fade={false} trigger="legacy"
        isOpen={popoverOpen} target={`PopoverMegaMenu${data.label}`} toggle={handleToggle}>
          <div className="dropdown-mega-menu">
            <div className="grid-menu grid-menu-3col">
              <Row className="no-gutters">
                {data.content.map((item, index) => {
                    return (
                      <Col xl="4" sm="6" key={index}>
                        <Nav vertical>
                          {
                          (item.content === undefined || data.content.length === 0) ?
                            ((item.to !== undefined) ?
                              <NavLink onClick={handleToggle} href={item.to}>
                                <span>{item.label}</span>
                              </NavLink>
                            : '')
                          :
                            <>
                            <NavItem className="nav-item-header">{item.label}</NavItem>
                            {item.content.map((item2, index2) => {
                              return (
                                <NavItem key={index2}>
                                  <NavLink onClick={handleToggle} href={item2.to}>
                                    <span>{item2.label}</span>
                                  </NavLink>
                                </NavItem>
                              )
                            })}
                            </>
                          }
                        </Nav>
                      </Col>
                    )
                  })}
                </Row>
            </div>
          </div>
        </Popover>
      }
    </>
  );
}

export default NavItemMega;
