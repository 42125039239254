import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter, Route, Redirect, Switch } from "react-router-dom"

import LoadingOverlay from "react-loading-overlay";
import { Loader } from "react-loaders";

import { ToastContainer } from "react-toastify";

import ResizeDetector from "react-resize-detector";

import { FormGroup, Label, Row, Col, Button, Modal, ModalHeader, ModalBody, Input, FormFeedback, Table, InputGroupAddon, InputGroupText, InputGroup, ModalFooter } from "reactstrap";
import ReactInputMask from "react-input-mask";
import { RiEyeCloseLine } from "react-icons/ri";
import { ImEye } from "react-icons/im";
import { ErrorToast, InfoToast, NotificationToast, SuccessToast } from "../../Layout/AppMain/Components/Toast";

import AppMain from "../../Layout/AppMain";
import LoginPage from "../../Pages/UserPages/Login";
import RegisterPage from "../../Pages/UserPages/Register";
import LoginAccessSupport from "../../Pages/UserPages/LoginAccessSupport";
import ForgotPasswordPage from "../../Pages/UserPages/ForgotPassword";
import RecoveryPasswordPage from "../../Pages/UserPages/RecoveryPassword";

import Echo from 'laravel-echo';
import api from "../../services/api";
import { getInfoUser, logout } from "../../services/auth";
import { Clicksign, formatDate, formatPassword } from "../../services/utils";
import PasswordForce from '../../Layout/AppMain/Components/PasswordForce/index';
window.Pusher = require('pusher-js');

const PrivateRoute = ({ component: Component, setLoading, setLoadTyper, isBlocked, setIsBlocked, isAuthenticated, setIsAuthenticated, useHere, width, notifications, updateUser, setUpdateUser, ...rest }) => (
  <LoadingOverlay tag="div" active={isBlocked}
  styles={{
    overlay: (base) => ({
      ...base,
      background: "#f1f4f6",
      color: "#000",
      position: 'fixed',
      zIndex: '99999999999999'
    }),
  }}
  spinner={false}
  text={<Row style={{textAlign: 'right'}}>
    <Col md={12}>
      <FormGroup>
        <Label>Acesso ativo em outra janela, clique em "Usar aqui" para utiliza-lo nesta janela ou clique em "Desconectar" para voltar a página de login</Label>
      </FormGroup>
    </Col>
    <Col md={12}>
      <Button style={{margin: '10px', fontSize: '15px'}} onClick={async () => {
        setIsBlocked(false);
        setLoading(true);
        await logout();
        setLoading(false);
      }} color="danger" outline={true} >Desconectar</Button>
      <Button style={{margin: '10px', fontSize: '15px'}} onClick={useHere} color="primary" >Usar aqui</Button>
    </Col>
  </Row>}
  >
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component
            {...props}
            width={width}
            setLoading={(isActive) => setLoading(isActive)}
            setLoadTyper={(typeLoad) => setLoadTyper(typeLoad)}
            setIsAuthenticated={(auth) => setIsAuthenticated(auth)}
            notifications={notifications}
            updateUser={updateUser}
            setUpdateUser={setUpdateUser}
          />
        ) : (
          isAuthenticated !== null &&
          <Redirect to="/login" />
        )
      }
    />
  </LoadingOverlay>
);

const Main = (props) => {
  const [loadActive, setLoadActive] = useState(false);
  const [loaderType, setLoadTyper] = useState('pacman');
  const [updateUser, setUpdateUser] = useState(false);
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const userDataRef = useRef([]);
  userDataRef.current = users;
  const [isBlocked, setIsBlocked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [modalContract, setModalContract] = useState(false);
  const [modalPassword, setModalPassword] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showDocument, setShowDocument] = useState(false);
  const [validation, setValidation] = useState({});
  const defaultFormData = {
    old_password: '',
    password: '',
    confirm_password: '',
    level: 0
  }
  const [formData, setFormData] = useState({
    ...defaultFormData
  });
  const [showPasswordForce, setShowPasswordForce] = useState(false);
  const [viewOldPassword, setViewOldPassword] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [contractDocument, setContractDocument] = useState({});

  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = props;

  const useHere = () => {
    let allUsers = [...userDataRef.current];
      for (let i in allUsers) {
        if (allUsers[i].token == user.token) {
          allUsers[i].blocked = false;
        } else {
          allUsers[i].blocked = true;
        }
      }
      setIsBlocked(false);
      setUsers([...allUsers]);

      window.Echo.join(`App.Models.User.${user.id}`).whisper('useHere', user)
  }

  const handleClickNotification = (notification) => {
    let url = notification.url ? notification.url : `/#/notificacoes/visualiza/${notification.id}`
    window.open(url)

    if (!notification.date_viewed) {
      api.post(`notifications/${notification.id}/viewed`)
      .then(response => {
        setUser({
          ...user,
          notifications: user.notifications.map(not => {
            if (not.id === notification.id) {
              return {
                ...response.data
              }
            }

            return not
          })
        })
      })
      .catch(e => {
        console.log(e)
      })
    }
  }

  const handleNotify = async (n) => {
    if (document.visibilityState === 'visible') {
      NotificationToast({ title: n.title, message: n.message, onClick: () => handleClickNotification(n) })
    }

    if (Notification.permission === 'granted' && document.visibilityState === 'hidden') {
      const notification = new Notification(n.title, {
        body: n.message
      })

      notification.onclick = () => handleClickNotification(n)
    }
  }

  const requestPermissionNotification = async () => {
    if (!Notification) {
      console.log('Esse browser não suporta notificações desktop');
    } else {
      if (!['denied', 'granted'].includes(Notification.permission)) {
        // Pede ao usuário para utilizar a Notificação Desktop
        await Notification.requestPermission();
      }
    }
  }

  const handleGanerateClicksign = async () => {
    let invalid = {}
    if (!formData.agent_name || !formData.agent_name.trim()) {
      invalid.agent_name = true
    }
    if (!formData.agent_cpf || !formData.agent_cpf.trim()) {
      invalid.agent_cpf = true
    }
    if (Object.keys(invalid).length > 0) {
      setValidation(invalid)
      ErrorToast({ placeholder: 'Os campos marcados com * são obrigatórios' })
      return
    }

    setValidation({})
    try {
      setLoadActive(true)
      let response = await api.post('companies/contract/clicksign', {
        agent_name: formData.agent_name,
        agent_cpf: formData.agent_cpf,
        agent_email: user.email
      })
      setShowForm(false)
      setFormData({})
      hadleClicksignDocument(response.data)
    } catch (e) {
      console.log(e)
      setLoadActive(false)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar gerar o documento, tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
    }
  }

  const handleVerifyClickSign = async ({ contract_id, key }) => {
    if (!contract_id || !key) {
      return false
    }
    try {
      setLoadActive(true)
      let response = await api.post('companies/contract/clicksign/verify', {
        contract_id,
        key,
        agent_email: user.email
      })

      if (!response.data.errors && !response.data.contractors) {
        hadleClicksignDocument(response.data)
      } else {
        setLoadActive(false)
        let msg = response.data.errors ? response.data.errors.join('<br />') : 'Ocorreu um erro interno ao tentar consultar o documento, verifique os dados utilizados no mesmo'
        InfoToast({ placeholder: msg })

        if (response.data.contractors) {
          let signers = response.data.contractors.map(signer => {
            let status = 'Pendente de Assinatura'
            switch(signer.signature.validation.status) {
              case 'conferred':
                status = 'Confirmada'
                break;
              case 'divergent_name':
                status = 'Divergente: Nome do signatário não confere';
                break;
              case 'birthday_not_found':
                status = 'Divergente: Data de Nascimento do signatário não confere';
                break;
              case 'cpf_not_found':
                status = 'Divergente: CPF do signatário não existe';
                break;
              case 'cpf_invalid':
                status = 'Divergente: CPF do signatário é invalido';
                break;
              default:
              break;
            }

            return {
              ...signer,
              status
            }
          })

          if (user.plan.status === 2) {
            setContractDocument({})
            setShowDocument(false)
            setShowForm(true)
          }

          setFormData({
            ...formData,
            data_signers: signers
          })
        }
      }
    } catch (e) {
      console.log(e)
      setLoadActive(false)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar buscar o documento, tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
    }
  }

  const hadleClicksignDocument = (key) => {
    try {
      let widget = new Clicksign(key)
      widget.endpoint = process.env.REACT_APP_CLICKSIGN_HOST || 'https://app.clicksign.com';

      widget.origin = window.location.origin
      widget.mount('widgetEmbeddedCSUpdate')

      /**Callback para quando o widget for carregado */
      widget.on('loaded', () => {
        console.log('widget loaded!')
        setLoadActive(false)
        setShowDocument(true)
      })

      /**Callback para quando o usuário assinar o documento */
      widget.on('signed', () => {
        handleCheckClicksign()
      })
    } catch (e) {
      console.log(e)
      setLoadActive(false)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar buscar as informações do documento, tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
    }
  }

  const handleCheckClicksign = async () => {
    setLoadActive(true)
    /**Realiza um post para marcar que o contrato está em processo de assinatura */
    try {
      await api.post('companies/contract/clicksign/check')
      setModalContract(false)
      setShowDocument(false)
      setShowForm(true)
      setContractDocument({})
      setFormData({})
    } catch (e) {
      console.log(e)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar assinar o contrato, tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
    } finally {
      setLoadActive(false)
    }
  }

  const handleChangePassword = (value, prop) => {
    setFormData({
      ...formData,
      [prop]: formatPassword(value)
    })
  }

  const toggleViewOldPassword = () => {
    setViewOldPassword(!viewOldPassword);
  }

  const toggleViewPassword = () => {
    setViewPassword(!viewPassword);
  }

  const toggleViewConfirmPassword = () => {
    setViewConfirmPassword(!viewConfirmPassword);
  }

  const handleValidatePassword = () => {
    let invalid = {}
    if (!formData.old_password) {
      invalid.old_password = true
    }
    if (!formData.password || formData.level < 6) {
      invalid.password = true
    }
    if (formData.password && (!formData.confirm_password || formData.confirm_password !== formData.password)) {
      invalid.confirm_password = true
    }

    if (Object.keys(invalid).length > 0) {
      setValidation(invalid)
      ErrorToast({ placeholder: 'Os campos marcados com * são obrigatórios' })
      return false
    }

    setValidation({})
    return true
  }

  const handleFormPassword = () => {
    return {
      old_password: formData.old_password,
      password: formData.password,
      confirm_password: formData.confirm_password,
      edit_password: 1
    }
  }

  const handleUpdatePassword = async () => {
    if (!handleValidatePassword()) {
      return
    }

    try {
      setLoadActive(true)
      await api.put(`users/${user.id}`, handleFormPassword())
      SuccessToast({ placeholder: 'Senha alterada com sucesso!' })
      setModalPassword(false)
    } catch (e) {
      console.log(e)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar atualizar sua senha, tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
    } finally {
      setLoadActive(false)
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await api.get('users/me');
        let data = {
          ...getInfoUser(),
          ...response.data
        }
        localStorage.removeItem('@userInfo');
        localStorage.setItem('@userInfo', JSON.stringify(data));
        setIsAuthenticated(true);
        setUser(data);
        if (!data.company_id) {
          props.history.push('/filiais')
        }
      } catch (e) {
        console.log(e);
        setIsAuthenticated(false);
      }
    }

    getData();
  }, [updateUser]);

  useEffect(() => {
    if (user.id === undefined) {
      return;
    }
    const options = {
      broadcaster: 'pusher',
      key: '9cbababdd85a841674e2',
      forceTLS: true,
      wsHost: process.env.REACT_APP_WBSOCKET_HOST || 'api.fuganholi-hub.com.br',
      wsPort: process.env.REACT_APP_WEBSOCKET_PORT || 6001,
      wssPort: process.env.REACT_APP_WEBSOCKET_PORT || 6001,
      authorizer: (channel, options) => {
        return {
          authorize: (socketId, callback) => {
            api.interceptors.request.use(async config => {
              config.headers['X-Socket-ID'] = socketId;
              return config;
            });
            api.post('broadcasting/auth', {
                socket_id: socketId,
                channel_name: channel.name,
                token: user.token
            }).then(response => {
                callback(false, response.data);
            })
            .catch(error => {
                callback(true, error);
            })
          }
        }
      }
    }
    window.Echo = new Echo(options);
    window.Echo.join(`App.Models.User.${user.id}`)
    .here(users => {
      setUsers(users);
    })
    .joining(u => {
      let allUsers = [...userDataRef.current];
      allUsers = allUsers.filter(u2 => u2.token !== u.id);
      for (let i in allUsers) {
        allUsers[i].blocked = true;
      }
      allUsers.push({...u, blocked: false});
      for (let i in allUsers) {
        if (allUsers[i].token == user.token) {
          setIsBlocked(allUsers[i].blocked);
        }
      }
      setUsers([...allUsers]);
    })
    .leaving(u => {
      let allUsers = [...userDataRef.current];
      allUsers = allUsers.filter(u2 => u2.token !== u.token);
      setUsers(allUsers)
    })
    .listenForWhisper('useHere', u => {
      let allUsers = [...userDataRef.current];
      allUsers = allUsers.filter(u2 => u2.token !== u.token);
      for (let i in allUsers) {
        allUsers[i].blocked = true;
      }
      allUsers.push({...u, blocked: false});
      for (let i in allUsers) {
        if (allUsers[i].token == user.token) {
          setIsBlocked(allUsers[i].blocked);
        }
      }
      setUsers([...allUsers]);
    })
    .listen('NewNotification', r => {
      api.get('users/me')
      .then(response => {
        let data = {
          ...getInfoUser(),
          ...response.data
        }

        localStorage.removeItem('@userInfo');
        localStorage.setItem('@userInfo', JSON.stringify(data));
        setUser(data);
        handleNotify(r.notification)
      })
      .catch(e => {
        console.log(e)
      })
      api.post(`notifications/${r.notification.id}/received`)
      .catch(e => {
        console.log(e)
      })
    })
    .listen('AccessBlock', r => {
      api.get('users/me')
      .then(response => {
        let data = {
          ...getInfoUser(),
          ...response.data
        }

        localStorage.removeItem('@userInfo');
        localStorage.setItem('@userInfo', JSON.stringify(data));
        props.history.push('/bloqueado');
        setUser(data);
      })
      .catch(e => {
        console.log(e)
      })
    });

    if (user.type === 2) {
      window.Echo.join(`AccessSupport.Company.${user.company_id}.User.${user.id}`)
      .listen('DisconnectAccessSupport', async r => {
        if (r.access.company_id === user.company_id && r.user.id === user.id) {
          await logout();
        }
      })
    }
  }, [user.id, user.token]);

  useEffect(() => {
    if (isAuthenticated) {
      requestPermissionNotification()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (user && user.plan && [0, 2].includes(user.plan.status) && !user.plan.filial) {
      if (user.plan.documents.filter(document => (!['closed', 'canceled'].includes(document.status) && document.type === 1)).length > 0) {
        let doc = user.plan.documents.filter(document => (!['closed', 'canceled'].includes(document.status) && document.type === 1))[0]
        setContractDocument(doc)
        handleVerifyClickSign(doc)
      }
      setModalContract(true);
      return
    }

    setModalContract(false)
  }, [user.plan])

  useEffect(() => {
    if (user && user.plan && user.force_change_password && (![0, 2].includes(user.plan.status) || user.plan.filial)) {
      setModalPassword(true)
      return
    }

    setModalPassword(false)
  }, [user.force_change_password, user.plan])

  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <>
        <LoadingOverlay styles={{
          overlay: (base) => ({
            ...base,
            position: 'fixed',
            zIndex: '999999999999'
          })
        }} tag="div" active={loadActive}
          spinner={<Loader active type={loaderType} />}>
          <div
            className={cx(
              "app-container app-theme-" + colorScheme,
              { "fixed-header": enableFixedHeader },
              { "fixed-sidebar": enableFixedSidebar || width < 1250 },
              { "fixed-footer": enableFixedFooter },
              { "closed-sidebar": enableClosedSidebar || width < 1250 },
              { "closed-sidebar-mobile": true,},
              { "sidebar-mobile-open": enableMobileMenu },
              { "body-tabs-shadow-btn": enablePageTabsAlt }
            )}>
              <Switch>
                <Route exact path="/login/:access_support_token" render={(props) => (
                  <LoginAccessSupport
                    {...props}
                    setLoading={(isActive) => setLoadActive(isActive)}
                    setLoadTyper={(typeLoad) => setLoadTyper(typeLoad)}
                    isAuthenticated={isAuthenticated}
                  />
                )}/>
                <Route exact path="/login" render={(props) => (
                  <LoginPage
                    {...props}
                    setLoading={(isActive) => setLoadActive(isActive)}
                    setLoadTyper={(typeLoad) => setLoadTyper(typeLoad)}
                    isAuthenticated={isAuthenticated}
                  />
                )}/>
                <Route exact path="/forgot-password" render={(props) =>
                  <ForgotPasswordPage
                    {...props}
                    setLoading={(isActive) => setLoadActive(isActive)}
                    setLoadTyper={(typeLoad) => setLoadTyper(typeLoad)}
                    isAuthenticated={isAuthenticated} />
                } />
                <Route exact path="/recovery-password/:user_id/:token" render={(props) =>
                  <RecoveryPasswordPage
                    {...props}
                    setLoading={(isActive) => setLoadActive(isActive)}
                    setLoadTyper={(typeLoad) => setLoadTyper(typeLoad)}
                    isAuthenticated={isAuthenticated} />
                } />
                <Route exact path="/register" render={(props) => (
                  <RegisterPage
                    {...props}
                    setLoading={(isActive) => setLoadActive(isActive)}
                    setLoadTyper={(typeLoad) => setLoadTyper(typeLoad)}
                    isAuthenticated={isAuthenticated}
                  />
                )}/>
                <PrivateRoute path="/"
                  component={AppMain}
                  width={width}
                  setLoading={(isActive) => setLoadActive(isActive)}
                  setLoadTyper={(typeLoad) => setLoadTyper(typeLoad)}
                  setIsBlocked={(blocked) => setIsBlocked(blocked)}
                  isBlocked={isBlocked}
                  useHere={useHere}
                  isAuthenticated={isAuthenticated}
                  setIsAuthenticated={(auth) => setIsAuthenticated(auth)}
                  notifications={user.notifications}
                  updateUser={updateUser}
                  setUpdateUser={setUpdateUser}
                />
              </Switch>
              <Modal size="lg" isOpen={modalContract} toggle={() => {}}>
                <ModalHeader>Contrato digital</ModalHeader>
                <ModalBody>
                    <Row>
                      <Col className="fixe-height-modal">
                        <Row>
                          <Col>
                            <h6>{user && user.plan && user.plan.status === 2 ? 'Os dados informados na assinatura da contratação do sistema foram validados e apresentaram algumas divergências. Pedimos que verifique os dados utilizados e assine novamente com as informações corretas.' : 'Olá, para podermos dar continuidade aos serviços prestados a sua empresa é necessário que realize a assinatura digital do seu contrato. Siga os passos abaixo para assinar o mesmo.'}</h6>
                          </Col>
                        </Row>
                        <br />
                        <Row hidden={Object.keys(contractDocument).length > 0 || !showForm}>
                          <Col>
                            <h5 className="card-title">Dados do representante legal da empresa</h5>
                            <Row>
                              <Col md={6} sm={12}>
                                <FormGroup>
                                  <Label>Nome <span className="text-danger">*</span></Label>
                                  <Input type='text' invalid={validation.agent_name} value={formData.agent_name} onChange={({ target: { value } }) => setFormData({ ...formData, agent_name: value })} />
                                  <FormFeedback>Informe um nome válido</FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col md={3} sm={12}>
                                <FormGroup>
                                  <Label for="cpfcnpj">CPF</Label>
                                  <ReactInputMask dusk="cpf" className="form-control" mask="999.999.999-99" name="cpfcnpj" onChange={({ target: { value } }) => setFormData({ ...formData, agent_cpf: value })} value={formData.agent_cpf}/>
                                </FormGroup>
                              </Col>
                              <Col md={3} sm={6}>
                                <FormGroup>
                                  <Button style={{ marginTop: 28 }} color="primary" onClick={handleGanerateClicksign}>Continuar</Button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <br hidden={Object.keys(contractDocument).length > 0 || !showForm} />
                        {
                          formData.data_signers &&
                          <>
                            <Row>
                              <Col>
                                <h5 className="card-title">Dados utilizados</h5>
                                <Table>
                                  <thead>
                                    <tr>
                                      <th>Status</th>
                                      <th>Nome</th>
                                      <th>Email</th>
                                      <th>Documento</th>
                                      <th>Data Nascimento</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      formData.data_signers.map((signer, key) => {
                                        return <tr key={key}>
                                          <td>{signer.status}</td>
                                          <td>{signer.name}</td>
                                          <td>{signer.email}</td>
                                          <td>{signer.documentation}</td>
                                          <td>{signer.signature ? formatDate({ value: `${signer.signature.birthday}T00:00:00`, location: 'Br', format: 'Date' }) : ''}</td>
                                        </tr>
                                      })
                                    }
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                            <br hidden={!formData.data_signers} />
                          </>
                        }
                        <br />
                        <Row hidden={Object.keys(contractDocument).length === 0 && !showDocument}>
                          <Col>
                            <h5 className="card-title">Documento para Assinatura</h5>
                            <div hidden={!showDocument} id="widgetEmbeddedCSUpdate" style={{height: 427}}></div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                </ModalBody>
              </Modal>
              <Modal size="lg" isOpen={modalPassword} toggle={() => {}}>
                <ModalHeader>Alterar senha</ModalHeader>
                <ModalBody>
                    <Row>
                      <Col>
                        <h5 className="card-title">Para manter suas informações mais seguras, precisamos que altere sua senha utilizando os campos abaixo:</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4} sm={12}>
                        <FormGroup>
                          <Label for="old_password">Senha Atual <b>*</b></Label>
                          <InputGroup>
                            <Input type={viewOldPassword ? 'text' : 'password'} invalid={validation.old_password} id="old_password" value={formData.old_password} onChange={({ target: { value } }) => handleChangePassword(value, 'old_password')} placeholder="Informe a senha atual..." />
                            <InputGroupAddon style={{cursor: 'pointer'}} addonType="append" onClick={toggleViewOldPassword}>
                                <InputGroupText>
                                  <RiEyeCloseLine hidden={viewOldPassword}/>
                                  <ImEye hidden={!viewOldPassword} />
                                </InputGroupText>
                            </InputGroupAddon>
                            <FormFeedback>Informe a sua senha atual do sistema</FormFeedback>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={12}>
                        <FormGroup>
                          <Label for="password">Nova Senha <b>*</b></Label>
                          <InputGroup>
                            <Input type={viewPassword ? 'text' : 'password'} invalid={validation.password} id="password" value={formData.password} onChange={({ target: { value } }) => handleChangePassword(value, 'password')} onBlur={() => setShowPasswordForce(false)} onFocus={() => setShowPasswordForce(true)} placeholder="Informe a nova senha..." />
                            <InputGroupAddon style={{cursor: 'pointer'}} addonType="append" onClick={toggleViewPassword}>
                                <InputGroupText>
                                  <RiEyeCloseLine hidden={viewPassword}/>
                                  <ImEye hidden={!viewPassword} />
                                </InputGroupText>
                            </InputGroupAddon>
                            <FormFeedback>Informe uma senha válida</FormFeedback>
                          </InputGroup>
                          {
                            showPasswordForce &&
                            <>
                              <br />
                              <PasswordForce password={formData.password} onLevel={value => setFormData({
                                ...formData,
                                level: value
                              })} />
                            </>
                          }
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={12}>
                        <FormGroup>
                          <Label for="confirm_password">Confirmar Senha <b>*</b></Label>
                          <InputGroup>
                            <Input type={viewConfirmPassword ? 'text' : 'password'} invalid={validation.confirm_password} id="confirm_password" value={formData.confirm_password} onChange={({ target: { value } }) => handleChangePassword(value, 'confirm_password')} placeholder="Confirme a nova senha..." />
                            <InputGroupAddon style={{cursor: 'pointer'}} addonType="append" onClick={toggleViewConfirmPassword}>
                                <InputGroupText>
                                  <RiEyeCloseLine hidden={viewConfirmPassword}/>
                                  <ImEye hidden={!viewConfirmPassword} />
                                </InputGroupText>
                            </InputGroupAddon>
                            <FormFeedback>Informe a mesma senha digitada no campo Nova Senha</FormFeedback>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                  <Row style={{margin: 0, width: '100%'}}>
                    <Col md="12">
                      <Button style={{float: 'right'}} color="primary" onClick={handleUpdatePassword}>
                        Alterar Senha
                      </Button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Modal>
          </div>
          </LoadingOverlay>
          <ToastContainer />
          </>
      )}
    />
  );
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(Main));

