import React, { useEffect, useState } from "react";

import Slider from "react-slick";

import bg1 from "../../../assets/utils/images/originals/city.jpg";
import bg2 from "../../../assets/utils/images/originals/citydark.jpg";
import bg3 from "../../../assets/utils/images/originals/citynights.jpg";

import { Col, Row, Button, FormGroup, Label, Input, CustomInput, InputGroup, InputGroupAddon, InputGroupText, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import { RiEyeCloseLine } from "react-icons/ri";
import { ImEye } from "react-icons/im";
import PasswordForce from "../../../Layout/AppMain/Components/PasswordForce";
import { ErrorToast } from "../../../Layout/AppMain/Components/Toast";
import validator from 'validator'
import { formatPassword } from "../../../services/utils";
import api from "../../../services/api";


const Register = ({ history, setLoading, isAuthenticated }) => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    initialSlide: 0,
    autoplay: true,
    adaptiveHeight: true,
  };
  const [ validation, setValidation ] = useState({});
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [showPasswordForce, setShowPasswordForce] = useState(false);
  const defaultFormData = {
    name: '',
    email: '',
    password: '',
    confirm_password: '',
    accept_terms: false,
    password_level: 0
  }
  const [formData, setFormData] = useState({
    ...defaultFormData
  });

  const toggleViewPassword = () => {
    setViewPassword(!viewPassword);
  }

  const toggleViewConfirmPassword = () => {
    setViewConfirmPassword(!viewConfirmPassword);
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  const handleChange = (value, prop) => {
    let newData = {...formData}
    if (['password', 'confirm_password'].includes(prop)) {
      value = formatPassword(value)
    }

    newData[prop] = value

    setFormData({
      ...newData
    })
  }

  const handleValidate = () => {
    let invalid = {}

    if (!formData.email || !validator.isEmail(formData.email)) {
      invalid.email = true
    }

    if (!formData.password || formData.password_level < 6) {
      invalid.password = true
    }

    if (formData.password && formData.confirm_password !== formData.password) {
      invalid.confirm_password = true
    }

    if (Object.keys(invalid).length > 0) {
      setValidation(invalid)
      ErrorToast({ placeholder: 'Os campos marcados com * são obrigatórios' })
      return false
    }

    if (!formData.accept_terms) {
      invalid.accept_terms = true
      setValidation(invalid)
      ErrorToast({ placeholder: 'É necessário aceitar nossos termos e condições antes de prosseguir!' })
      return false
    }

    setValidation({})
    return true
  }

  const handleFormData = () => {
    return {
      ...formData,
      force_change_password: false
    }
  }

  const handleSubmit = async () => {
    if (!handleValidate()) {
      return
    }

    try {
      setLoading(true)
      await api.post('register', handleFormData())
      setFormData({
        ...defaultFormData
      })
      history.push('/login')
    } catch (e) {
      console.log(e)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar realizar o registro, tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => setValidation({ ...validation, email: (!formData.email || !validator.isEmail(formData.email)) }), [formData.email])

  useEffect(() => setValidation({ ...validation, password: (!formData.password || formData.password_level < 6) }), [formData.password, formData.password_level])

  useEffect(() => setValidation({ ...validation, confirm_password: (formData.password && formData.confirm_password !== formData.password) }), [formData.confirm_password])

  useEffect(() => {
    if (validation.accept_terms) {
      setValidation({ ...validation, accept_terms: !formData.accept_terms })
    }
  }, [formData.accept_terms])

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated])

  return (
    <>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <Col lg="4" className="d-none d-lg-block">
            <div className="slider-light">
              <Slider {...settings}>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div className="slide-img-bg"
                    style={{
                      backgroundImage: "url(" + bg1 + ")",
                    }}/>
                  <div className="slider-content">
                    <h3>Perfect Balance</h3>
                    <p>
                      ArchitectUI is like a dream. Some think it's too good to
                      be true! Extensive collection of unified React Boostrap
                      Components and Elements.
                    </p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                  <div className="slide-img-bg"
                    style={{
                      backgroundImage: "url(" + bg3 + ")",
                    }}/>
                  <div className="slider-content">
                    <h3>Scalable, Modular, Consistent</h3>
                    <p>
                      Easily exclude the components you don't require.
                      Lightweight, consistent Bootstrap based styles across
                      all elements and components
                    </p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                  <div className="slide-img-bg opacity-6"
                    style={{
                      backgroundImage: "url(" + bg2 + ")",
                    }}/>
                  <div className="slider-content">
                    <h3>Complex, but lightweight</h3>
                    <p>
                      We've included a lot of components that cover almost all
                      use cases for any type of application.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </Col>
          <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
              <div className="app-logo" />
              <h4>
                <div>Bem Vindo,</div>
                <span>
                  Leva apenas alguns segundos para criar a sua conta
                </span>
              </h4>
              <div>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="email">
                        <span className="text-danger">*</span> E-mail
                      </Label>
                      <Input invalid={validation.email} type="email" name="email" id="email" placeholder="Seu melhor e-mail..." value={formData.email} onChange={({ target: { value } }) => handleChange(value, 'email')} autoComplete="off" onKeyUp={handleKeyUp} />
                      <FormFeedback>Informe um e-mail válido</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="name">Nome</Label>
                      <Input invalid={validation.name} type="text" name="text" id="name" placeholder="Seu nome e sobrenome..." value={formData.name} onChange={({ target: { value } }) => handleChange(value, 'name')} autoComplete="off" onKeyUp={handleKeyUp} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="password">
                        <span className="text-danger">*</span> Senha
                      </Label>
                      <InputGroup>
                        <Input invalid={validation.password} type={viewPassword ? 'text' : 'password'} name="password" id="password" placeholder="Senha de acesso..." value={formData.password} onChange={({ target: { value } }) => handleChange(value, 'password')} onBlur={() => setShowPasswordForce(false)} onFocus={() => setShowPasswordForce(true)} autoComplete="off" onKeyUp={handleKeyUp} />
                        <InputGroupAddon style={{cursor: 'pointer'}} addonType="append" onClick={toggleViewPassword}>
                            <InputGroupText>
                              <RiEyeCloseLine hidden={viewPassword}/>
                              <ImEye hidden={!viewPassword} />
                            </InputGroupText>
                        </InputGroupAddon>
                        <FormFeedback>Informe uma senha válida</FormFeedback>
                      </InputGroup>
                      {
                        showPasswordForce &&
                        <>
                          <br />
                          <PasswordForce password={formData.password} onLevel={value => handleChange(value, 'password_level')} />
                        </>
                      }
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="confirm_password">
                        <span className="text-danger">*</span> Confirme a Senha
                      </Label>
                      <InputGroup>
                        <Input invalid={validation.confirm_password} type={viewConfirmPassword ? 'text' : 'password'} name="confirm_password" id="confirm_password" placeholder="Repita a senha de acesso..." value={formData.confirm_password} onChange={({ target: { value } }) => handleChange(value, 'confirm_password')} autoComplete="off" onKeyUp={handleKeyUp} />
                        <InputGroupAddon style={{cursor: 'pointer'}} addonType="append" onClick={toggleViewConfirmPassword}>
                            <InputGroupText>
                              <RiEyeCloseLine hidden={viewConfirmPassword}/>
                              <ImEye hidden={!viewConfirmPassword} />
                            </InputGroupText>
                        </InputGroupAddon>
                        <FormFeedback>Informe a mesma senha digitada no campo Senha</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <span>
                    <InputGroup>
                      <CustomInput invalid={validation.accept_terms} type="checkbox" id="accept_terms" checked={formData.accept_terms} onChange={({ target: { checked } }) => handleChange(checked, 'accept_terms')} label="Aceitar nossos" />
                      &nbsp;
                      <a href="https://nfservice.com.br/termos" target="_blank" rel="noopener noreferrer">
                        Termos e Condições
                      </a>
                    </InputGroup>
                  </span>
                </FormGroup>
                <Row className="divider" />
                <h6 className="mt-3">
                  Já possui uma conta?{" "}
                  <Link id="login" to="/login">
                    Entrar
                  </Link>
                </h6>
                <div className="d-flex align-items-center">
                  <div className="ml-auto">
                    <Button type="button" color="primary" size="lg" onClick={handleSubmit}>
                      Criar Conta
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Register;
