import axios from "axios";
import { getInfoUser, logout } from "./auth";
axios.defaults.withCredentials = true;
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://api.fuganholi-hub.com.br/api"
});
api.CancelToken = axios.CancelToken;
// Intercepta qualquer resposta da API que contenha o codigo HTTP 401, indicando acesso não autorizado, e redireciona o cliente para a tela de login para que possa logar novamente no sistema
api.interceptors.response.use(response => {
  return response;
}, err => {
  return new Promise(async (resolve, reject) => {
    const user = getInfoUser();
    if (user && err.response && err.response.status === 401) {
      await logout()
      window.location.reload()
    } else if (user && err.response && err.response.status === 402) {
      window.location.assign('/#/bloqueado');
    } else if (!axios.isCancel(err)) {
      let data = err.response !== undefined && err.response.data !== undefined ? err.response.data : null;
      let status = err.response !== undefined && err.response.status !== undefined ? err.response.status : null;
      reject({
        ...data,
        status,
      })
    }
  });
});

export default api;
