import React, { Fragment } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Col,
  Row,
  Button,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
} from "reactstrap";

import { FaTicketAlt, FaHeadset, FaRegUser } from "react-icons/fa";

import { getInfoUser, logout } from "../../../services/auth";
import { useEffect } from "react";
import { useState } from "react";

const UserBox = ({history, setLoading}) => {
  const [user, setUser] = useState({});
  const [toggleDropDown, setToggleDropDown] = useState(false);

  const handleToggleDropDown = () => {
    setToggleDropDown(!toggleDropDown);
  }

  useEffect(() => {
    setUser(getInfoUser());
  }, []);

  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left ml-3 header-user-info">
              <div className="widget-heading">{user.name}</div>
              <div className="widget-subheading">{user.email}</div>
            </div>
            <div className="widget-content-left">
              <UncontrolledButtonDropdown isOpen={toggleDropDown}>
                <DropdownToggle onClick={handleToggleDropDown} className="p-0 ml-2 mr-2" color="link">
                  <div className="icon-wrapper icon-wrapper-alt rounded-circle" style={{height: 44, width: 44}}>
                    <div className="icon-wrapper-bg bg-primary" />
                    <FaRegUser style={{margin: "0 auto"}} color="var(--primary)" fontSize="20px" />
                  </div>
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-primary">
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                              <div className="widget-heading">
                                {user.company !== undefined && <>{user.company.name}</>}
                              </div>
                              {/* <div className="widget-subheading opacity-8">
                              {user.email}
                              </div> */}
                            </div>
                            <div className="widget-content-right mr-2">
                              <Button className="btn-pill btn-shadow btn-shine" color="focus" onClick={async () => {
                                setLoading(true);
                                await logout();
                                setLoading(false);
                                history.push('/');
                              }}>
                                Sair
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="scroll-area-xs"
                    style={{
                      height: "150px",
                    }}>
                    <PerfectScrollbar>
                      <Nav onClick={handleToggleDropDown} vertical>
                        <NavItem>
                          <NavLink href="#">
                            Meus Dados
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink href="#">
                            Meus Usuários
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink href="/#/dados_empresa">
                            Dados da Empresa
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink href="#">
                            Meu Plano
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </PerfectScrollbar>
                  </div>
                  <Nav vertical>
                    <NavItem className="nav-item-divider mb-0" />
                  </Nav>
                  <div className="grid-menu grid-menu-2col">
                    <Row onClick={handleToggleDropDown} className="no-gutters">
                      <Col sm="6">
                        <Button className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                          outline color="primary">
                            <FaHeadset size="2rem" />
                            <Col md="12">
                              <b>Acesso Suporte</b>
                            </Col>
                        </Button>
                      </Col>
                      <Col sm="6">
                        <Button className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                          outline color="success">
                          <FaTicketAlt size="2rem" />
                          <Col md="12">
                            <b>Meus Tickets</b>
                          </Col>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UserBox;
