import React from "react";

import { Nav } from "reactstrap";
import NavItemMega from "./Components/NavItemMega";

import {
  SalesNav,
  PurchasesNav,
  FinancialNav,
  ReportsNav
} from "../../../AppNav/NavItems";

const MegaMenu = () => {
  return (
    <>
      <Nav className="header-megamenu">
        <NavItemMega data={SalesNav[0]} />
        <NavItemMega data={PurchasesNav[0]} />
        <NavItemMega data={FinancialNav[0]} />
        <NavItemMega data={ReportsNav[0]} />
      </Nav>
    </>
  );
}

export default MegaMenu;
