import { Route, Switch, Redirect } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import Loader from "react-loader-advanced";
import cx from "classnames";

import AppHeader from "../AppHeader";
import AppSidebar from "../AppSidebar";

const Product = lazy(() => import("../../Pages/Product"));
const Company = lazy(() => import("../../Pages/Company"));
const Person = lazy(() => import("../../Pages/Person"));
const Platform = lazy(() => import("../../Pages/Platform"));

const AppMain = (props) => {
  return (
    <>
      <AppHeader {...props} />
      <div className="app-main">
        {props.width <= 991 && <AppSidebar />}
        <div className="app-main__outer">
          <div className={cx(
            "app-main__inner",
            {"col-md-10 offset-md-1": props.width > 991}
          )}>
            <Suspense fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader show={true} type="ball-spin-fade-loader"/>
                    </div>
                    <h6 className="mt-5">
                        Carregando componentes...
                    </h6>
                </div>
              </div>
            }>
              <Switch>
                  {/** Aqui deve ser definidas as rotas do sistema */}
                  <Route exact path="/" render={() => {
                    return (<h1>DashBoard Sistema</h1>)
                  }} />
                  <Route path="/produtos" render={({match}) => <Product {...props} match={match} />
                  } />
                  <Route path={["/plataformas", "/erps", "/logistica", "/ferramentas"]} render={({match}) => <Platform {...props} match={match} />
                  } />
                  <Route path="/clientes" render={({match}) => <Person {...props} match={match} />
                  } />
                  <Route path="/fornecedores" render={({match}) => <Person {...props} match={match} />
                  } />
                  <Route path="/transportadoras" render={({match}) => <Person {...props} match={match} />
                  } />
                  <Route path="/vendedores" render={({match}) => <Person {...props} match={match} />
                  } />
                  <Route path="/impostos" render={({match}) => <Person {...props} match={match} />
                  } />
                  <Route path="/dados_empresa" render={({match}) => <Company {...props} match={match} />
                  } />
                  <Route render={() => <Redirect to="/" />} />
              </Switch>
            </Suspense>
          </div>
        </div>
      </div>
    </>
  )
};

export default AppMain;
