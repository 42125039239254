import React from "react";
import cx from "classnames";

import { connect } from "react-redux";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import HeaderLogo from "../AppLogo";

import UserBox from "./Components/UserBox";

import HeaderDots from "./Components/HeaderDots";

import MegaMenu from "./Components/MegaMenu";

const Header = (props) => {
  const {
    headerBackgroundColor,
    enableMobileMenuSmall,
    enableHeaderShadow,
  } = props;

  return (
    <>
      <TransitionGroup component="div" appear={true} enter={false} exit={false} className={cx("app-header", headerBackgroundColor, {
          "header-shadow": enableHeaderShadow,
        })}>
        <CSSTransition timeout={800} classNames="HeaderAnimation">
          <HeaderLogo />
        </CSSTransition>
        <CSSTransition timeout={800} classNames="HeaderAnimation">
          <div className={cx("app-header__content", {
              "header-mobile-open": enableMobileMenuSmall,
            })}>
            <div className="app-header-left">
              <MegaMenu {...props} />
            </div>
            <div className="app-header-right">
              <HeaderDots {...props} />
              <UserBox {...props}/>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
}

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
