import moment from 'moment';
import 'moment/locale/pt-br';

String.prototype.replaceAll = function(de, para){
	var str = this.toString();
	var pos = str.indexOf(de);
	while (pos > -1){
		str = str.replace(de, para);
		pos = str.indexOf(de);
	}
	return (str);
}

export const replaceAll = (value, from, to) => {
  let oldValue = value == null ? '' : String(value);
  return oldValue.replaceAll(from, to);
}

export const formatValue = ({ value, separator = '.', currency = '', locale = 'pt-BR', decimal = true, maxFractionDigitis = 2 }) => {
  var valueFormated = '0.00';

  if (currency !== '') {
    valueFormated = Intl.NumberFormat(locale, {style: 'currency', currency: currency, maximumFractionDigits: maxFractionDigitis}).format(parseFloat(value ? value : 0));
  } else {
    let oldValue = value == null ? '' : String(value);
    if (decimal && value !== '') {
      var valueSplit = oldValue.split('.');
      if (typeof valueSplit[1] === 'undefined' || valueSplit[1].length === 1) {
        if (typeof valueSplit[1] === 'undefined') {
          valueSplit[1] = '00';
        } else {
          valueSplit[1] = valueSplit[1]+'0';
        }
      }
      oldValue = valueSplit.join('.');
    }
    valueFormated = oldValue.replace('.', separator);
  }

  return valueFormated;
}

export const formatFloat = ({ value }) => {
  let oldValue = value == null ? '' : String(value);

  return oldValue.replace(/[^\d,.-]+/g, '').replace(',', '.');
}

export const formatPhone = ({ value }) => {
  let oldValue = !value ? '' : String(value.replace(/[^\d]+/g, ''));
  var valueFormated = '';
  if (oldValue.length > 0 && oldValue.length <= 10) {
    valueFormated = '('+oldValue.substr(0, 2)+') '+oldValue.substr(2, 4)+'-'+oldValue.substr(6, 4)
  } else if (oldValue.length > 0 && oldValue.length === 11) {
    valueFormated = '('+oldValue.substr(0, 2)+') '+oldValue.substr(2, 5)+'-'+oldValue.substr(7, 4)
  } else {
    valueFormated = oldValue
  }

  return valueFormated
}

export const formatCpfCnpj = ({ value, type = 'CNPJ' }) => {
  let oldValue = !value ? '' : String(value.replace(/[^\d]+/g, ''));
  var valueFormated = '';
  if (type === 'CNPJ') {
    oldValue = oldValue.substr(0, 14);
    if (oldValue.length >= 13) {
      valueFormated = oldValue.substr(0, 2)+'.'+oldValue.substr(2, 3)+'.'+oldValue.substr(5, 3)+'/'+oldValue.substr(8, 4)+'-'+oldValue.substr(12, 2);
    } else if (oldValue.length >= 9) {
      valueFormated = oldValue.substr(0, 2)+'.'+oldValue.substr(2, 3)+'.'+oldValue.substr(5, 3)+'/'+oldValue.substr(8, 4);
    } else if (oldValue.length >= 6) {
      valueFormated = oldValue.substr(0, 2)+'.'+oldValue.substr(2, 3)+'.'+oldValue.substr(5, 3);
    } else if (oldValue.length >= 3) {
      valueFormated = oldValue.substr(0, 2)+'.'+oldValue.substr(2, 3);
    }else {
      valueFormated = oldValue;
    }
  } else {
    oldValue = oldValue.substr(0, 11);
    if (oldValue.length >= 10) {
      valueFormated = oldValue.substr(0, 3)+'.'+oldValue.substr(3, 3)+'.'+oldValue.substr(6, 3)+'-'+oldValue.substr(9, 2);
    } else if (oldValue.length >= 7) {
      valueFormated = oldValue.substr(0, 3)+'.'+oldValue.substr(3, 3)+'.'+oldValue.substr(6, 3);
    } else if (oldValue.length >= 4) {
      valueFormated = oldValue.substr(0, 3)+'.'+oldValue.substr(3, 3);
    } else {
      valueFormated = oldValue;
    }
  }

  return valueFormated;
}

export const onlyNumber = ({ value }) => {
  let oldValue = !value ? '' : String(value);
  return oldValue.replace(/[^\d]+/g, '');
}

export const formatPassword = value => {
  let oldValue = !value ? '' : String(value);
  return oldValue.replace(/[^\da-zA-Z!@#$%&]+/g, '');
}

export const formatDate = ({ value, location = 'Br', format = 'DateHour' }) => {
  if (value === '' || value === null) {
    return ''
  }
  var valueFormated = '';
  if (['Date', 'DateHour'].includes(format)) {
    if (location === 'Br') {
      if (format === 'DateHour') {
        valueFormated = moment(value).format('DD/MM/YYYY HH:mm:ss');
      } else if (format === 'Date') {
        valueFormated = moment(value).format('DD/MM/YYYY');
      }
    } else if ('Date') {
      if (format === 'DateHour') {
        valueFormated = moment(value).format('YYYY-MM-DD HH:mm:ss');
      } else if (format === 'Date') {
        valueFormated = moment(value).format('YYYY-MM-DD');
      }
    }
  } else {
    if (format == 'Hour') {
      valueFormated = moment(value).format('HH:mm');
    } else if (format == 'Day') {
      valueFormated = moment(value).format('DD');
    } else if (format == 'Month') {
      valueFormated = moment(value).format('MM');
    } else if (format == 'Year') {
      valueFormated = moment(value).format('YYYY');
    } else {
      valueFormated = moment(value).locale('pt-br').format(format);
    }
  }

  return valueFormated;
}

export const removeFormatCpfCnpj = ({ value }) => {
  return value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
}

export const removeFormatCep = ({ value }) => {
  return value.replaceAll('.', '').replaceAll('-', '');
}

export const removeFormatPhone = ({ value }) => {
  return value.replaceAll('_', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '');
}

export const addDateDays = (date, quantity = 1) => {
  var newDate = new Date(date);
  return newDate.setDate(newDate.getDate() + quantity);
}

export const addDateMonths = (date, quantity = 1) => {
  var newDate = new Date(date);
  return newDate.setMonth(newDate.getMonth() + quantity);
}

export const addDateYears = (date, quantity = 1) => {
  var newDate = new Date(date);
  return newDate.setFullYear(newDate.getFullYear() + quantity);
}

export const removeDays = (date, quantity = 1) => {
  var newDate = new Date(date);
  return newDate.setDate(newDate.getDate() - quantity);
}

export const removeMonths = (date, quantity = 1) => {
  var newDate = new Date(date);
  return newDate.setMonth(newDate.getMonth() - quantity);
}

export const removeYears = (date, quantity = 1) => {
  var newDate = new Date(date);
  return newDate.setFullYear(newDate.getFullYear() - quantity);
}

export const diffDate = ({dateOne, dateTwo, type = 'M', incrementTotal = 0, notNegative = false}) => {
  let now = new Date(dateOne);
  let date = new Date(dateTwo);

  let diff = 0;
  if (type === 'M') {
    diff = Math.ceil(date.getMonth() - now.getMonth());
    if (date.getFullYear() > now.getFullYear()) {
      diff += ((date.getFullYear() - now.getFullYear()) * 12);
    }
  } else if (type === 'D') {
    diff = (now - date)/86400000;
  }
  diff += incrementTotal;

  if (notNegative && diff < 0) {
    diff *= -1;
  }

  return diff;
}

export function multiple (...args) {
  let totMultiple = args.map((value) => {
    let valueCorrect = 0;
    if (!isNaN(parseFloat(String(value).replace(',', '.')))) {
      valueCorrect = parseFloat(String(value).replace(',', '.'));
    }
    return valueCorrect;
  }).reduce((accumulator, currentValue) => accumulator * currentValue)
  totMultiple = (totMultiple*100000000000)/100000000000;
  return totMultiple;
}

export const dateNow = (type = null, format = 'YYYY-MM-DD') => {
  if (type === 'first') {
    return moment().startOf('month').format(format)
  } else if (type === 'last') {
    return moment().endOf('month').format(format)
  }

  return moment().format(format)
}

export const Clicksign = (o) => {"use strict";var r,u,t=window.location.protocol+"//"+window.location.host,e={},n=function(t){(e[t]||[]).forEach(function(t){t()})},c=function(t){n(t.data)};return{endpoint:"https://app.clicksign.com",origin:t,mount:function(t){var n="/sign/"+o,e="?embedded=true&origin="+this.origin,i=this.endpoint+n+e;return u=document.getElementById(t),(r=document.createElement("iframe")).setAttribute("src",i),r.setAttribute("style","width: 100%; height: 100%;"),window.addEventListener("message",c),u.appendChild(r)},unmount:function(){return r&&(u.removeChild(r),r=u=null,window.removeEventListener("message",n)),!0},on:function(t,n){return e[t]||(e[t]=[]),e[t].push(n)},trigger:n}}

export const typesImg = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png']
export const typesPdf = ['application/pdf']
export const typesCode = ['text/xml', 'text/html']
export const typesCsv = ['text/csv']
